import { AuthControl } from "../authControl";
import { Campground, CampgroundPropsUser } from "../models/campground";
import { Service, ServiceImpl } from "./service";
import { ResponseJSON } from "./serviceUtils";

export interface AdminCampgroundsService extends Service {
  getCampground(campgroundId: string): Promise<{campground: Campground & { descriptionFileName?: string }, campgroundOverrides: Campground & { descriptionFileName?: string }}>;
  updateCampground(campgroundId: string, campground: CampgroundPropsUser & { descriptionFileName?: string }): Promise<Campground & { descriptionFileName?: string }>;
}

export class AdminCampgroundsServiceImpl extends ServiceImpl implements AdminCampgroundsService {  
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)
  };

  async getCampground(campgroundId: string): Promise<{campground: Campground, campgroundOverrides: Campground}> {
    return await this.get(`/admin/v1/campgrounds/${campgroundId}`, transformCampgroundResponseJSON);
  }

  async updateCampground(campgroundId: string, campground: CampgroundPropsUser): Promise<Campground> {
    return await this.patch(`/admin/v1/campgrounds/${campgroundId}`, campground, transformUpdateResponseJSON);
  }

};

type campgroundResponseJSON = ResponseJSON & {campground: Campground & { descriptionFileName?: string }, campgroundOverrides: Campground & { descriptionFileName?: string }};
function transformCampgroundResponseJSON(responseJSON: campgroundResponseJSON): {campground: Campground & { descriptionFileName?: string }, campgroundOverrides: Campground } {
  return { campground: responseJSON.campground, campgroundOverrides: responseJSON.campgroundOverrides }
}

type updateResponseJSON = ResponseJSON & Campground;
function transformUpdateResponseJSON(responseJSON: updateResponseJSON): Campground & { descriptionFileName?: string } {
  delete responseJSON.status;
  const campground: Campground & { descriptionFileName?: string } = responseJSON;

  return campground;
}
