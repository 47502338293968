import { AuthControl } from "../authControl";
import { OpenAIThread } from "../models/openAIThread";
import { Service, ServiceImpl } from "./service";
import { ResponseJSON } from "./serviceUtils";

export interface AdminOpenAIThreadsService extends Service {
  getOpenAIThread(openAIThreadId: string): Promise<OpenAIThread>;
}

type OpenAIThreadResponseJSON = ResponseJSON & OpenAIThread;

function openAIThreadFromResponseJSON(responseJSON: OpenAIThreadResponseJSON): OpenAIThread {
  delete responseJSON.status;
  const openAIThread: OpenAIThread = responseJSON;

  return openAIThread;
};

export class AdminOpenAIThreadsServiceImpl extends ServiceImpl implements AdminOpenAIThreadsService {  
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)
  }

  async getOpenAIThread(openAIThreadId: string): Promise<OpenAIThread> {
    return await this.get(`/admin/v1/openAIThreads/${openAIThreadId}`, openAIThreadFromResponseJSON);
  }
};
