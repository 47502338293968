import { AuthControl } from "../authControl";

import { RoadtripsService, RoadtripsServiceImpl } from "./roadtripsService";
import { StopsService, StopsServiceImpl } from "./stopsService";
import { ProfilesService, ProfilesServiceImpl } from "./profilesService";
import { InterestsService, InterestsServiceImpl } from "./interestsService";
import { ReferralsService, ReferralsServiceImpl } from "./referralsService";
import { AffiliatesService, AffiliatesServiceImpl } from "./affiliatesService";
import { TimezonesService, TimezonesServiceImpl } from "./timezonesService";
import { EventsService, EventsServiceImpl } from "./eventsService";
import { AdsService, AdsServiceImpl } from "./adsService";
import { AlertsService, AlertsServiceImpl } from "./alertsService";


import { AdminAdsService, AdminAdsServiceImpl } from "./adminAdsService";
import { AdminProfilesService, AdminProfilesServiceImpl } from "./adminProfilesService";
import { AdminUsersService, AdminUsersServiceImpl } from "./adminUsersService";
import { AdminOperationsService, AdminOperationsServiceImpl } from "./adminOperationsService";
import { AdminRoadtripsService, AdminRoadtripsServiceImpl } from "./adminRoadtripsService";
import { AdminCampgroundsService, AdminCampgroundsServiceImpl } from "./adminCampgroundsService";
import { AdminOpenAIAssistantsService, AdminOpenAIAssistantsServiceImpl } from "./adminOpenAIAssistantsService";
import { AdminOpenAIThreadsService, AdminOpenAIThreadsServiceImpl } from "./adminOpenAIThreadsService";
import { AdminOpenAIMessagesService, AdminOpenAIMessagesServiceImpl } from "./adminOpenAIMessagesService";
import { AdminActionsService, AdminActionsServiceImpl } from "./adminActionsService";
import { ActionsService, ActionsServiceImpl } from "./actionsService";
import { CampgroundsService, CampgroundsServiceImpl } from "./campgroundsService";

export interface Services {
  readonly roadtripsService: RoadtripsService;
  readonly stopsService: StopsService;
  readonly profilesService: ProfilesService;
  readonly interestsService: InterestsService;
  readonly referralsService: ReferralsService;
  readonly affiliatesService: AffiliatesService;
  readonly timezonesService: TimezonesService;
  readonly eventsService: EventsService;
  readonly adsService: AdsService;
  readonly actionsService: ActionsService;
  readonly alertsService: AlertsService;
  readonly campgroundsService: CampgroundsService;
}
export class ServicesImpl implements Services {
  public readonly roadtripsService: RoadtripsService;
  public readonly stopsService: StopsService;
  public readonly profilesService: ProfilesService;
  public readonly interestsService: InterestsService;
  public readonly referralsService: ReferralsService;
  public readonly affiliatesService: AffiliatesService;
  public readonly timezonesService: TimezonesService;
  public readonly eventsService: EventsService;
  public readonly adsService: AdsService;
  public readonly actionsService: ActionsService;
  public readonly alertsService: AlertsService;
  public readonly campgroundsService: CampgroundsService;
  
  constructor(apiServer: string, authControl: AuthControl) {

    this.roadtripsService = new RoadtripsServiceImpl(
      apiServer,
      authControl,
    );

    this.stopsService = new StopsServiceImpl(
      apiServer,
      authControl
    );
  
    this.profilesService = new ProfilesServiceImpl(
      apiServer,
      authControl
    );
  
    this.interestsService = new InterestsServiceImpl(
      apiServer,
      authControl
    );
  
    this.referralsService = new ReferralsServiceImpl(
      apiServer, 
      authControl
    );
  
    this.affiliatesService = new AffiliatesServiceImpl(
      apiServer, 
      authControl
    );
  
    this.timezonesService = new TimezonesServiceImpl(
      apiServer
    );
  
    this.eventsService = new EventsServiceImpl(
      apiServer,
      authControl
    );
  
    this.adsService = new AdsServiceImpl(
      apiServer,
      authControl
    );
  
    this.actionsService = new ActionsServiceImpl(
      apiServer,
      authControl
    );
  
    this.alertsService = new AlertsServiceImpl(
      apiServer,
      authControl
    )    

    this.campgroundsService = new CampgroundsServiceImpl(
      apiServer, 
      authControl
    )

  }
};
export interface AdminServices {
  readonly adminActionsService: AdminActionsService;
  readonly adminAdsService: AdminAdsService;
  readonly adminProfilesService: AdminProfilesService;
  readonly adminUsersService: AdminUsersService;
  readonly adminOperationsService: AdminOperationsService;
  readonly adminRoadtripsService: AdminRoadtripsService;
  readonly adminCampgroundsService: AdminCampgroundsService;
  readonly adminOpenAIAssistantsService: AdminOpenAIAssistantsService;
  readonly adminOpenAIThreadsService: AdminOpenAIThreadsService;
  readonly adminOpenAIMessagesService: AdminOpenAIMessagesService;
};

export class AdminServicesImpl implements AdminServices {

  public readonly adminActionsService: AdminActionsService;
  public readonly adminAdsService: AdminAdsService;
  public readonly adminProfilesService: AdminProfilesService;
  public readonly adminUsersService: AdminUsersService;
  public readonly adminOperationsService: AdminOperationsService;
  public readonly adminRoadtripsService: AdminRoadtripsService;
  public readonly adminCampgroundsService: AdminCampgroundsService;
  public readonly adminOpenAIAssistantsService: AdminOpenAIAssistantsService;
  public readonly adminOpenAIThreadsService: AdminOpenAIThreadsService;
  public readonly adminOpenAIMessagesService: AdminOpenAIMessagesService;
  
  constructor( apiServer: string, authControl: AuthControl) {
    this.adminActionsService = new AdminActionsServiceImpl(apiServer, authControl);
    this.adminAdsService = new AdminAdsServiceImpl(apiServer, authControl);
    this.adminProfilesService = new AdminProfilesServiceImpl(apiServer, authControl);
    this.adminUsersService = new AdminUsersServiceImpl(apiServer, authControl);
    this.adminOperationsService = new AdminOperationsServiceImpl(apiServer, authControl);
    this.adminRoadtripsService = new AdminRoadtripsServiceImpl(apiServer, authControl);
    this.adminCampgroundsService = new AdminCampgroundsServiceImpl(apiServer, authControl);
    this.adminOpenAIAssistantsService = new AdminOpenAIAssistantsServiceImpl(apiServer, authControl);
    this.adminOpenAIThreadsService = new AdminOpenAIThreadsServiceImpl(apiServer, authControl);
    this.adminOpenAIMessagesService = new AdminOpenAIMessagesServiceImpl(apiServer, authControl);
  }
  
}

export function createClientServices(apiServer: string, authControl: AuthControl): Services {
  return new ServicesImpl(apiServer, authControl);
}

export function createClientAdminServices(apiServer: string, authControl: AuthControl): AdminServices {
  return new AdminServicesImpl(apiServer, authControl);
}

export type { RoadtripsService } from "./roadtripsService";
export type { StopsService } from "./stopsService";
export type { ProfilesService } from "./profilesService";
export type { InterestsService } from "./interestsService";
export type { ReferralsService } from "./referralsService";
export type { AffiliatesService } from "./affiliatesService";
export type { TimezonesService } from "./timezonesService";
export type { EventsService } from "./eventsService";
export type { AdsService } from "./adsService";
export type { ActionsService } from "./actionsService";

export type { AdminAdsService } from "./adminAdsService";
export type { AdminProfilesService } from "./adminProfilesService";
export type { AdminUsersService } from "./adminUsersService";
export type { AdminOperationsService } from "./adminOperationsService";
export type { AdminRoadtripsService } from "./adminRoadtripsService";
export type { AdminCampgroundsService } from "./adminCampgroundsService";
export type { AdminOpenAIAssistantsService } from "./adminOpenAIAssistantsService";
export type { AdminOpenAIThreadsService } from "./adminOpenAIThreadsService";
export type { AdminOpenAIMessagesService } from "./adminOpenAIMessagesService";
export type { AdminActionsService } from "./adminActionsService";

